:root {
    --swiper-theme-color: #00958a;
}

.filterContainer {
    h2 {
        font-size: 1rem;
        color: #00958a;
    }

    .container {
        display: flex;
        flex-direction: row;
        max-width: 1000px;
        width: 100%;
        justify-content: space-around;
    }
}