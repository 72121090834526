
.contentContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;
    > div {
        position: relative;
        padding: 0 2.5rem 0 0;
        min-height: 75px;
    }

    .left {
        align-self: flex-start;
        flex: 50%;
        // flex: 1 1 50%;
        width: 50%;
        img {
            width: 100%;
        }
    }

    .right {
        justify-self: flex-end;
        // flex: 1 1 50%;
        flex: 50%;
        width: 50%;
        img {
            width: 100%;
        }
    }

    .full {
        align-self: flex-start;
        // flex: 1 1 100%;
        flex: 100%;
        width: 100%;

        img {
            width: 100%;
        }
    }
}

.alignHandle {
    position: absolute;
    right: 5px;
    top: 5px;
}

.sortHandle {
    position: absolute;
    right: 5px;
    top: 40px;
}