.footer {
	width: 100%;
	display: block;
	padding: 1rem;
	position: relative;

	.baseFooter {
		// display: flex;
		// flex-direction: column;
		position: relative;
		display: flex;
    	flex-direction: column;

		.footerlinks {
			align-self: flex-end;
			padding-top: 1rem;
			ul {
				padding: 0;
				list-style: none;
				margin: 0;

				li,
				a {
					color: #00958a;
					text-decoration: none;
					font-weight: bold;
					text-align: right;
					font-size: 14px;
					cursor: pointer;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.footerLogoContainer {
			margin-top: 2rem;
			align-self: flex-end;
			.footerLogo {
				height: 50px;
				width: 168px;
			}
		}
	}
}

@media (min-width: 1024px) and (orientation: landscape) {
	.footer {
		width: 100%;
		overflow-x: hidden;
		display: grid;
		grid-template-columns: 90% 10%;

		.baseFooter {
			display: flex;
			flex-direction: column;

			.footerlinks {
				align-self: flex-end;
				ul {
					padding: 0;
					list-style: none;
					margin: 0;

					li,
					a {
						color: #00958a;
						text-decoration: none;
						font-weight: bold;
						text-align: right;
						font-size: 14px;
						cursor: pointer;
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}

			.footerLogoContainer {
				// margin-top: 2rem;
				align-self: flex-end;
				.footerLogo {
					height: 50px;
					width: 168px;
				}
			}
		}
	}
}
