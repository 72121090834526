.settings {
    position: relative;
    padding-top: 1rem;
    grid-area: settings;
}

@media (min-width: 768px) {
    .settings {
        padding-top: 0;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media (min-width: 1024px) and (orientation: landscape) {
    .settings {
        padding-top: 1rem;
        padding-left: 1rem;
    }
}