@import '~antd/dist/antd.css';

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Helvetica";
}

canvas {
  flex: 1;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-touch-action: none;
  touch-action: pan-y;
  image-rendering: -webkit-optimize-contrast;
}

button {
  outline: none;
}

.App {
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.loadingContainer {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.debug {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #ccc;
  color: red;
  padding: 0 .5rem;
}