.item {
    overflow: hidden;
    padding: .2rem;
    border-radius: 10px 10px 0 10px;
    border: 2px solid #fff;
    margin: 1rem 0 .5rem;
    box-sizing: border-box;
    box-shadow: 2px 2px 7px 1px rgba(100, 100, 100, .5);
    max-height: 230px;
    max-width: 230px;
    position: relative;

    .close {
        background-color: #00958a;
        width: 30px;
        height: 30px;
        color: #fff;
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 0 0 7px;
        cursor: pointer;
    }

    &.active {
        border-color: #00958a;
        .close {
            display: flex;
        }
    }
    &.inactive {
        .baseInner {
            opacity: 0.5;
        }
        .baseName {
            opacity: 0.5;
        }
    }
}
.separator {
    width: 2rem;
    font-size: 2rem;
    position: absolute;
    margin: 0 1rem;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    right: -58px;
}

.noSlideSeparator {
    right: -55px;
    top: 0;
}
.baseItem {
    position: relative;
    width: 100%;

    .baseInner {
        min-width: 100%;
        min-height: 160px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .baseName {
        font-size: .7rem;
        color: rgb(0, 52, 74);
        font-weight: bold;
        position: absolute;
        bottom: -1rem;
        left: 1rem;
    }

    .baseTypes {
        position: absolute;
        bottom: 0rem;
        right: 0rem;
        width: 40px;
    }
}

@media (min-width: 768px) {
    .item {
        .baseTypes {
            width: 60px;
        }
    }
}

@media (min-width: 1024px) and (orientation: landscape) {
    .item {
        overflow: hidden;
        padding: 0;
        border-radius: 10px 10px 0 10px;
        width: 100%;
        height: auto;
        // margin-bottom: 2rem;
        &.active {
            box-shadow: 2px 2px 7px 1px rgba(100, 100, 100, .5);
        }
        &.inactive {
            .baseInner {
                opacity: 0.5;
                overflow: hidden;
            }
            .baseName {
                opacity: 0.5;
            }
        }
    }
    .baseItem {
        padding-top: 0rem;
        // height: 100%;
        height: initial;
        margin: auto;
        cursor: pointer;
        .baseInner {
            overflow: initial;
            border-radius: 10px 10px 0 10px;
        }

        .baseName {
            font-size: .7rem;
            color: rgb(0, 52, 74);
            font-weight: bold;
            position: absolute;
            bottom: .5rem;
            left: 1rem;
        }

        .baseTypes {
            position: absolute;
            bottom: 7px;
            right: 7px;
            width: 50px;
        }
    }
}