.headline {
    position: relative;
    color: rgb(0, 51, 99) !important;
}

.contents {
    position: relative;
    margin-bottom: 2rem;
}

.main {
    width: 80vw;
    max-width: 800px;
    max-height: 600px;
    background-color: #fff;
    padding: 1.5rem 1rem;
    overflow-y: scroll;
    border-radius: 0 0 20px;
    display: flex;
    flex-direction: column;

    label {
        font-weight: 200;
        color: #474747;
        margin-bottom: .5rem;
        margin-top: 1rem;
        &:first-child {
            margin-top: 0;
        }
    }

    legend {
        font-style: italic;
        font-size: 12px;
        color: #00958a;
    }

    .button {
        background-color: #00958a;
        border: 3px solid #00958a;
        color: #fff;
        padding: 0.7rem;
        font-size: 16px;
        border-radius: 4px;
        cursor: pointer;
        outline: none;
        margin-top: 1rem;
        font-size: 10px;
    }
    .error {
        color: red;
    }
}
