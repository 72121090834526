.editor {
    position: relative;
    img, video {
        max-width: 100%;
    }

    .close {
        position: absolute;
        top: .5rem;
        right: .5rem;
        cursor: pointer;
        > span {
            border-radius: 1rem;
            border: 1px solid #fff;
            color: #fff;
            background-color: #ff0000;

            padding: .5rem;
        }
    }
}

.alignHandle {
    position: absolute;
    right: 5px;
    top: 5px;
}