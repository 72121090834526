.container {
    grid-area: bases;
    > div {
        position: relative;
    }
    .swiperContainer {
        padding: 0 1rem !important;
        margin: 0 .5rem !important;
        .slideItem {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &.noSlider {
		display: grid;
		grid-gap: 2rem;
		grid-template-columns: 33% 33% 33%;
		.item {
			position: relative;
		}
	}

    .swipeNext,
    .swipePrev {
        position: absolute;
        top: 45%;
        width: calc(var(--swiper-navigation-size) / 44 * 27);
        height: var(--swiper-navigation-size);
        margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
        z-index: 10;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--swiper-navigation-color, var(--swiper-theme-color));

        &:after {
            font-family: swiper-icons;
            font-size: var(--swiper-navigation-size);
            text-transform: none !important;
            letter-spacing: 0;
            text-transform: none;
            font-variant: initial;
            line-height: 1;
        }
    }

    .swipeNext {
        right: -12px;
        left: auto;
        font-size: 25px;
        &:after {
            content: 'next';
        }
    }

    .swipePrev {
        left: -12px;
        right: auto;
        font-size: 25px;
        &:after {
            content: 'prev';
        }
    }

    .swipeDisabled {
        opacity: 0.35;
        cursor: auto;
        pointer-events: none;
    }
}

