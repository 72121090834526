.main {
	display: grid;
	width: 100%;
	height: 100vh;
	grid-template-rows: 3fr 1fr;
	.header {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-bottom: 3px solid #00958a;
		display: flex;
		justify-content: center;
		align-items: center;
		.bgImg {
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0.2;
			filter: grayscale(100%);
		}
		.headlineContainer {
			position: relative;
			max-width: 1000px;
			width: 100vw;
			color: rgb(18, 44, 73);
			font-size: 1.5rem;
			font-weight: bold;
			margin-top: 0vh;
			display: flex;
			justify-content: space-around;
			flex-direction: column;
			padding: 0 1rem;

			h1 {
				font-weight: bold;
				color: #00958a;
				margin-bottom: 0;
				padding-bottom: 0;
				line-height: 2rem;
				font-size: 2rem;
				max-width: 1000px;
				width: 100%;
			}

			p {
				font-weight: normal;
				padding-top: 0;
				font-size: 1.3rem;
			}

			.container {
				display: flex;
				flex-direction: row;
				max-width: 1000px;
				width: 100%;
				justify-content: space-around;
			}

			// .text {
			// 	display: flex;
			// 	flex-direction: column;
			// 	justify-content: center;
			// 	align-items: center;
			// 	.button {
			// 		border-radius: 13px;
			// 		border: 3px solid #00958a;
			// 		color: #00958a;
			// 		padding: .5rem 2rem;
			// 		font-size: 1.5rem;
			// 		font-weight: bold;
			// 		background-color: #fff;
			// 		text-decoration: none;
			// 		transition: all 0.2s;
			// 		position: relative;
			// 		top: unset;
			// 		display: inline-block;
			// 		width: auto;
			// 		margin-top: 1rem;

			// 		&:hover {
			// 			background-color: #00958a;
			// 			color: #fff;
			// 		}
			// 	}
			// }

			.questions {
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				height: 200px;
				margin-top: 10vh;
			}
		}
	}

	.button {
		border-radius: 23px;
		border: 3px solid #00958a;
		color: #00958a;
		padding: 1rem;
		font-size: 25px;
		font-weight: bold;
		background-color: #fff;

		position: absolute;
		top: -40px;
		text-decoration: none;

		width: 300px;
		text-align: center;
		transition: all 0.2s;

		&:hover {
			background-color: #00958a;
			color: #fff;
		}
	}
}
