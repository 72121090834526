.typesContainer {
    display: grid;
    grid-template-columns: 50% 50%;

    .type {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 1300ms;

        img {
            position: relative;
            width: 100%;
        }
    }
}