.close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    padding: .2rem;
    border-radius: 7px;
    background-color: #fff;
}

.technical {
    position: relative;
    z-index: 1;
    padding-top: 2rem;
    grid-area: technical;

    .buttonWrapper {
        position: relative;
        padding-top: .5rem;
    }
}

.modalContainer {
    background-color: #fff;
    overflow: -moz-scrollbars-none;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-height: 90vh;
    position: relative;
    display: block;
    padding: 0;

    .contentContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
        > div {
            position: relative;
            h2 {
                color: #00958a;
                font-weight: bold;
                font-size: 1.3rem;
            }

            h3 {
                color: #00958a;
                font-weight: bold;
                font-size: 1.1rem;
            }
            ul {
                padding-left: 1rem;
            }
        }
    }

    .documentContainer {
        h3 {
            color: #00958a;
            font-weight: bold;
            font-size: 1.1rem;
        }

        .video {
            width: 100%;
            margin-bottom: 1rem;
        }
        .documentWrapper {
            display: grid;
            grid-template-columns: 50% 50%;
            width: 100%;
            svg {
                margin-bottom: .5rem;
            }
            .download {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #00958a;
                word-wrap: break-word;
                word-break: break-all;
                background-color: #fff;
                transition: all 300ms;
                border-radius: 10px 10px 0 10px;
                padding: .5rem;
                &:hover {
                    text-decoration: none;
                    background-color: #eeeeee;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .modalContainer {
        display: grid;
        grid-template-columns: 75% 25%;
        .contentContainer {
            border-right: 2px solid #00958a;
            > div {
                h2 {
                    font-size: 2rem;
                }

                h3 {
                    font-size: 1.5rem;
                }
            }
        }
        .documentContainer {
            padding-left: 1rem;
            h3 {
                font-size: 1.2rem;
            }
        }
    }
    .technical {
        padding-top: 0;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media (min-width: 1024px) and (orientation: landscape) {
    .technical {
        padding-top: 1rem;
        padding-left: 3rem;
    }
}