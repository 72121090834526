.button,
.staticButton {
    border-radius: 7px 7px 0px 7px;
    border: 3px solid #00958a;
    color: #fff;
    padding: 0.5rem;
    font-size: 15px;
    background-color: #00958a;

    position: absolute;
    top: -74px;
    text-decoration: none;

    width: 50vw;
    text-align: center;
    transition: all 0.2s;

    cursor: pointer;

    &:hover {
        top: -77px;
        background-color: #00958a;
        color: #fff;
        border-width: 6px 8px 6px 8px;
        border-radius: 7px 7px 0px 7px;
    }
}

.staticButton {
    transition: none;

    &:hover {
        top: -74px;
        border-width: 3px;
        border-radius: 7px 7px 0px 7px;
    }
}

.alignedButton,
.staticAlignedButton {
    line-height: 1rem;
    display: block;
    border-radius: 7px 7px 0px 7px;
    border: 3px solid #00958a;
    color: #fff;
    padding: .5rem 1rem;
    font-size: 14px;
    font-weight: normal;
    background-color: #00958a;
    min-width: auto;
    text-decoration: none;
    text-align: center;
    transition: all 0.2s;
    margin: 3px 5px;

    cursor: pointer;

    &:hover {
        margin: 0px 2px;
        background-color: #00958a;
        color: #fff;
        border-width: 6px 8px 6px 8px;
        border-radius: 7px 7px 0px 7px;
    }
}

.staticAlignedButton {
    transition: none;

    &:hover {
        border-width: 3px;
        border-radius: 7px 7px 0px 7px;
        margin: 3px 5px;
    }
}

.disabledButton {
    background-color: rgba(0, 149, 138, .4);
    pointer-events: none;
    line-height: 1rem;
    display: block;
    border-radius: 7px 7px 0px 7px;
    border: 3px solid rgba(0, 149, 138, 0);
    color: #fff;
    padding: .5rem 1rem;
    font-size: 14px;
    font-weight: normal;
    min-width: auto;
    text-decoration: none;
    text-align: center;
    transition: all 0.2s;
    margin: 3px 5px;
}

@media (min-width: 700px) and (orientation: portrait) {
    .button,
    .staticButton {
        border-radius: 7px 7px 0px 7px;
        border: 3px solid #00958a;
        color: #fff;
        padding: 0.5rem;
        font-size: 15px;
        background-color: #00958a;

        position: absolute;
        top: -70px;
        text-decoration: none;

        width: 120px;
        box-sizing: content-box;
        text-align: center;
        transition: all 0.2s;

        &:hover {
            top: -73px;
            background-color: #00958a;
            color: #fff;
            border-width: 6px 8px 6px 8px;
            border-radius: 7px 7px 0px 7px;
        }
    }

    .staticButton {
        transition: none;

        &:hover {
            top: -70px;
            border-width: 3px;
            border-radius: 7px 7px 0px 7px;
        }
    }

    .alignedButton,
    .staticAlignedButton {
        font-size: 15px;
    }

    .disabledButton {
        font-size: 15px;
    }
}

@media (min-width: 768px) {
    .alignedButton,
    .staticAlignedButton {
        line-height: 2rem;
        display: block;
        border-radius: 7px 7px 0px 7px;
        border: 3px solid #00958a;
        color: #fff;
        padding: .5rem 1.5rem;
        font-size: 14px;
        font-weight: normal;
        background-color: #00958a;
        min-width: auto;
        text-decoration: none;
        text-align: center;
        transition: all 0.2s;
        margin: 3px 5px;
        &:hover {
            margin: 0px 2px;
            background-color: #00958a;
            color: #fff;
            border-width: 6px 8px 6px 8px;
            border-radius: 7px 7px 0px 7px;
        }
    }

    .staticAlignedButton {
        transition: none;

        &:hover {
            border-width: 3px;
            border-radius: 7px 7px 0px 7px;
            margin: 3px 5px;
        }
    }

    .disabledButton {
        line-height: 2rem;
        font-size: 14px;
    }
}

@media (min-width: 1024px) {
    .button,
    .staticButton {
        border-radius: 7px 7px 0px 7px;
        border: 3px solid #00958a;
        color: #fff;
        padding: 0.5rem;
        font-size: 15px;
        background-color: #00958a;

        position: absolute;
        top: 25px;
        text-decoration: none;

        width: 120px;
        box-sizing: content-box;
        text-align: center;
        transition: all 0.2s;

        &:hover {
            top: 22px;
            background-color: #00958a;
            color: #fff;
            border-width: 6px 8px 6px 8px;
            border-radius: 7px 7px 0px 7px;
        }
    }

    .staticButton {
        transition: none;

        &:hover {
            top: 25px;
            border-width: 3px;
            border-radius: 7px 7px 0px 7px;
        }
    }

    .alignedButton,
    .staticAlignedButton {
        font-size: 15px;
    }

    .disabledButton {
        font-size: 15px;
    }
}


@media (min-width: 1281px) and (orientation: landscape) {
    .button,
    .staticButton {
        border-radius: 7px 7px 0px 7px;
        border: 3px solid #00958a;
        color: #fff;
        padding: 0.5rem 1.5rem;
        font-size: 20px;
        background-color: #00958a;

        position: absolute;
        top: 30px;
        text-decoration: none;

        width: 120px;
        box-sizing: content-box;
        text-align: center;
        transition: all 0.2s;

        &:hover {
            top: 27px;
            background-color: #00958a;
            color: #fff;
            border-width: 6px 8px 6px 8px;
            border-radius: 7px 7px 0px 7px;
        }
    }

    .staticButton {
        transition: none;

        &:hover {
            top: 30px;
            border-width: 3px;
            border-radius: 7px 7px 0px 7px;
        }
    }

    .alignedButton,
    .staticAlignedButton {
        font-size: 20px;
    }
    .disabledButton {
        font-size: 20px;
    }
}