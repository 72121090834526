.actionsContainer {
    padding: 0 0 1rem 0;
    display: flex;
	position: absolute;
	width: 100%;
    top: 0;
    right: 0;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #00958a;
    padding-top: 1rem;
    z-index: 3;

    .actionButton {
        border: 2px solid #fff;
        border-radius: 10px;
        box-shadow: 2px 2px 7px 1px rgba(100, 100, 100, .5);
        background-color: #00958a;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        width: 60px;
        height: 60px;
        font-size: .7rem;
        padding: .4rem 0 .2rem;
        margin-left: .5rem;
        margin-right: .5rem;
        cursor: pointer;

        .icon {
            justify-self: center;
            align-self: center;
            display: block;
            width: 50px;
            path {
                width: 50px;
                fill: #fff;
                color: #fff;
            }
        }

        .rotate {
            transform: rotate(-90deg);
        }

        .large {
            display: block;
            font-size: 1.2rem;
            padding: .175rem;
        }
    }
}

@media (min-width: 768px) {
	.actionsContainer {
		position: absolute;
		background-color: transparent;
		width: auto;
	}
}

@media (min-width: 1024px) {
    .actionsContainer {
		padding: 0 1rem 1rem 0;
		display: flex;
		position: absolute;
		top: 0.5rem;
		right: 0;
		flex-direction: row;
		justify-content: space-evenly;
		background-color: transparent;
		padding-top: 0;

		.actionButton {
			border: 2px solid #fff;
			border-radius: 10px;
			box-shadow: 2px 2px 7px 1px rgba(100, 100, 100, .5);
			background-color: #00958a;
			color: #fff;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			font-weight: bold;
			width: 60px;
			height: 60px;
			font-size: .7rem;
			padding: .4rem 0 .2rem;
			margin-left: .5rem;
			margin-right: 0;
			cursor: pointer;

			.rotate {
				transform: rotate(-90deg);
			}

			.large {
				display: block;
				font-size: 1.2rem;
				padding: .175rem;
			}
		}
	}
}

@media (min-width: 1281px) and (orientation: landscape) {
    .actionsContainer {
		padding: 0 0 1rem 0;
		display: flex;
		position: absolute;
		top: 2rem;
		right: 2rem;
		flex-direction: row;
		justify-content: space-evenly;
		background-color: transparent;
		padding-top: 0;

		.actionButton {
			border: 2px solid #fff;
			border-radius: 10px;
			box-shadow: 2px 2px 7px 1px rgba(100, 100, 100, .5);
			background-color: #00958a;
			color: #fff;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			font-weight: bold;
			width: 60px;
			height: 60px;
			font-size: .7rem;
			padding: .4rem 0 .2rem;
			margin-left: .5rem;
			margin-right: 0;
			cursor: pointer;

			.rotate {
				transform: rotate(-90deg);
			}

			.large {
				display: block;
				font-size: 1.2rem;
				padding: .175rem;
			}
		}
	}
}