.mainView {
	width: 100vw;
	position: relative;
	.header {
		color: #fff;
		font-size: 1rem;
		font-weight: bold;
		width: 100%;
		background-color: #00958a;
		padding: 1rem;
	}
	canvas {
		height: 100%;
		width: 100%;
		object-fit: cover;
		cursor: grab;
	}
	.placeholder {
		position: relative;
		min-height: 100vw;
		width: 100vw;
		object-fit: cover;
	}
}

@media (min-width: 1024px) and (orientation: landscape) {
	.mainView {
		max-width: 1024px;
		margin: 0 auto;
		height: 60vh;
		width: auto;
		canvas {
			max-height: 100%;
			margin: 0 auto;
			display: block;
			width: auto;
		}
		.header {
			position: absolute;
			top: 1rem;
			left: 1rem;
			width: auto;
			background-color: transparent;
			color: #00958a;
			padding: 0.5rem;
		}
	}
}

