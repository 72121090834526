.button {
    color: #fff;
    background-color: rgba(0, 149, 138, 1);
    border-radius: 3px 3px 0 3px;
    padding: .4rem;
}

.table {
    tr[class*="ant-table-row-selected"] td,
    tr[class*="ant-table-row-selected"]:hover td {
        background: rgba(0, 149, 138, .2);
    }

    thead[class*="ant-table-thead"] th,
    .ant-table-small .ant-table-thead > tr > th {
        background-color: rgba(0, 149, 138, 1);
        color: #fff;
        font-weight: bold;
        font-size: 1rem;
    }
}

.buttons {
    display: grid;
    grid-template-columns: 100%;
}

@media (min-width: 768px) and (orientation: landscape) {
    .buttons {
        grid-template-columns: 50% 50%;
    }
}