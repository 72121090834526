.fileHandler {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
    img,
    video {
        max-width: 100%;
        object-fit: contain;
    }
}

.filter {
    margin-bottom: 2rem;
}

.label {
    color: #fff;
    background-color: rgba(24, 144, 255, 1);
    padding: .3rem .5rem;
    transition: all 200ms;
    border-radius: 5px;
    margin-top: .5rem;
    display: inline-block;
    font-size: 12px;
    max-width: 90%;
    word-break: break-all;
}