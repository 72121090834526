.content {
    height: auto;
    margin: 2rem !important;
    background-color: #fff;
}

.mainRow {
    // margin-bottom: 1rem;
}

.createContainer {
    margin-top: 1rem;
    overflow: hidden;
    max-height: 0;
}


.headline {
    color: rgb(0, 51, 99) !important;
}

.select {
    margin-bottom: 1rem !important;
}

.optionImage {
    position: relative;
    height: 100%;
    img {
        width: 30%;
        height: 100%;
    }
}

.optionLabel {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    height: 100%;
    text-transform: uppercase;
    font-weight: bolder;
}

.activeLabel {
    text-transform: uppercase;
    font-weight: normal;
}

.contents {
    position: relative;
}

.contentContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;
    > div {
        position: relative;
        padding: 0 2.5rem 0 0;
        min-height: 75px;
    }

    .left {
        align-self: flex-start;
        flex: 50%;
        // flex: 1 1 50%;
        width: 50%;
    }

    .right {
        justify-self: flex-end;
        // flex: 1 1 50%;
        flex: 50%;
        width: 50%;
    }

    .full {
        align-self: flex-start;
        // flex: 1 1 100%;
        flex: 100%;
        width: 100%;
    }
}

.alignHandle {
    position: absolute;
    right: 5px;
    top: 5px;
}

