.main {
	display: grid;
	width: 100%;
	height: 100vh;
	grid-template-rows: 3fr 1fr;
	> .header {
		position: relative;
		width: 100%;
        height: 100%;
        min-height: 100%;
		// overflow: hidden;
		// border-bottom: 3px solid #00958a;
		display: flex;
		justify-content: center;
		align-items: center;
		&::after {
			position: absolute;
			bottom: -44px;
			background-image: url("/border_shadow.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center top;
			width: 100%;
			height: 45px;
			content: "";
		}
		.bgImg {
			object-fit: cover;
			position: relative;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
        }

        .verifyContainer {
            position: absolute;
            color: rgb(4, 39, 57);
            font-size: 24px;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.4);
            padding: 2rem;
            border-radius: 1rem 1rem 0 1rem;
            transition: all .3s;
            p {
                margin-bottom: 0;
            }
        }
	}
}

@media (min-width: 700px) and (orientation: portrait) {
	.main {
		display: grid;
		width: 100%;
		height: 100vh;
		grid-template-rows: 3fr 1fr;
	}
}


@media (min-width: 1024px) and (orientation: landscape) {
	.main {
		display: grid;
		width: 100%;
		height: 100vh;
		grid-template-rows: 3fr 1fr;
		.header {
			position: relative;
			width: 100%;
			height: 100%;
			// overflow: hidden;
			// border-bottom: 3px solid #00958a;
			display: flex;
			justify-content: center;
			align-items: center;
			.bgImg {
				object-fit: cover;
				position: relative;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}

@media (min-width: 1281px) and (orientation: landscape) {
	.main {
		display: grid;
		width: 100%;
		height: 100vh;
		grid-template-rows: 75% 25%;
		.header {
			position: relative;
			width: 100%;
			height: 100%;
			// overflow: hidden;
			// border-bottom: 3px solid #00958a;
			display: flex;
			justify-content: center;
			align-items: center;
			.bgImg {
				object-fit: cover;
				position: relative;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}

