.content {
    height: auto;
    margin: 2rem !important;
    background-color: #fff;
}

.container {
    display: grid;
    grid-template-columns: 33% 33% 33%;

    .introCard {
        border: 1px solid #ccc;
        margin: 0 1rem 1rem;
        position: relative;
        padding: 1rem;

        .headline {
            color: #00958a;
            font-weight: bold;
            font-size: 1.3rem;
            margin-bottom: 0;
        }

        .subheadline {
            font-size: 1.1rem;
            margin-bottom: 1rem;
        }

        img {
            width: 100%;
        }

        .text {
            font-size: 1rem;
            text-align: center;
            margin-bottom: 1rem;
            > div {
                font-size: 1rem;
                text-align: center;
            }
        }

        .remove {
            position: absolute;
            top: .5rem;
            right: .5rem;
            border-radius: 1rem;
            border: 1px solid #fff;
            color: #fff;
            background-color: #ff0000;
            padding: .5rem;
            cursor: pointer;
        }
    }

    .addButton {
        margin: 0 1rem 1rem;
    }
}