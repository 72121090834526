.editUser {
    display: block;
    max-width: 100%;
    .hidden {
        display: none;
    }
    .saveContainer {
        display: flex;
        justify-content: flex-end;
        > div {
            max-width: 50%;
        }
    }
}


@media (min-width: 700px) {
    .editUser {
        display: grid;
        grid-template-columns: 50% 50%;
        max-width: 100%;
        .hidden {
            display: none;
        }
        .field {
            &:nth-child(2n) {
                padding-right: .5rem;
            }
            &:nth-child(3n) {
                padding-left: .5rem;
            }
            &:nth-child(5) {
                padding-left: .5rem;
            }
        }
        .saveContainer {
            display: flex;
            justify-content: flex-end;
            > div {
                max-width: 50%;
            }
        }
    }
}